import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/maven-pro/500.css';
import '@fontsource/maven-pro/700.css';

import { useRef } from 'react';
import shared from '@lib/components';
const { App } = shared;

import { MobileMenuProvider } from '@/stores/mobileMenu';
import { MenuProvider } from '@/stores/menu';
import { AuthProvider, useAuthStore, verify, ready } from '@/stores/auth';
import { useEffect } from 'react';

function _app ({ Component, pageProps }) {
  const hasLoadedAdScriptRef = useRef(false);

  useEffect(() => {
    const loadAdScript = () => {
      if (hasLoadedAdScriptRef.current) return;
  
      hasLoadedAdScriptRef.current = true;
  
      const scriptRef = document.getElementsByTagName('script')[ 0 ];
      const ympbScript = document.createElement('script');
  
      ympbScript.src = 'https://portal.cdn.yollamedia.com/storage/tag/ps36b86672399699726d34c38747d69d93c49f92a5.js';
      scriptRef.parentNode.insertBefore(ympbScript, scriptRef);
  
      window.removeEventListener('scroll', loadAdScript);
      window.removeEventListener('mousemove', loadAdScript);
      window.removeEventListener('touchstart', loadAdScript);
    };
  
    window.addEventListener('scroll', loadAdScript);
    window.addEventListener('mousemove', loadAdScript);
    window.addEventListener('touchstart', loadAdScript);

    return () => {
      window.removeEventListener('scroll', loadAdScript);
      window.removeEventListener('mousemove', loadAdScript);
      window.removeEventListener('touchstart', loadAdScript);
    }
  }, []);

  return (
    <MobileMenuProvider>
      <MenuProvider>
        <App.Provider
          authProvider={AuthProvider}
        >
          <App
            theme='iogames'
            readyAction={ready}
            verifyAction={verify}
            useAuthStore={useAuthStore}
          >
            <Component {...pageProps} />
          </App>
        </App.Provider>
      </MenuProvider>
    </MobileMenuProvider>
  );
}

export default _app;
